


































import { Vue, Component, Mixins } from 'vue-property-decorator'

import MyList from '@/mixins/list'

@Component
export default class extends Mixins(MyList) {
  dispatchType: string = 'stadium/list'

  mounted() {
    this.loadData(
      {
        page_size: 6,
      },
      false
    )
  }

  changePage(page: number) {
    this.loadData(
      {
        page,
        page_size: 6,
      },
      false
    )
  }
}
