



































































































































import { Vue, Component, Mixins } from 'vue-property-decorator'

import {
  use,
  registerMap,
  registerTheme,
  connect,
  disconnect,
} from 'echarts/core'
import {
  BarChart,
  LineChart,
  PieChart,
  MapChart,
  RadarChart,
  ScatterChart,
  EffectScatterChart,
  LinesChart,
} from 'echarts/charts'
import {
  GridComponent,
  PolarComponent,
  GeoComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  VisualMapComponent,
  DatasetComponent,
  ToolboxComponent,
  DataZoomComponent,
} from 'echarts/components'

import { CanvasRenderer, SVGRenderer } from 'echarts/renderers'

import VChart, { THEME_KEY } from 'vue-echarts'

use([
  BarChart,
  LineChart,
  PieChart,
  MapChart,
  RadarChart,
  ScatterChart,
  EffectScatterChart,
  LinesChart,
  GridComponent,
  PolarComponent,
  GeoComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  VisualMapComponent,
  DatasetComponent,
  CanvasRenderer,
  SVGRenderer,
  ToolboxComponent,
  DataZoomComponent,
])

// @ts-ignore
import MyDvBox from '$ui/dv/packages/my-dv-box'
// @ts-ignore
import MyDvBorder9 from '$ui/dv/packages/my-dv-border9'
// @ts-ignore
import MyDvTitle from '$ui/dv/packages/my-dv-title'

// @ts-ignore
import MyDvLoading from '$ui/dv/packages/my-dv-loading'

// @ts-ignore
import MyDvNumber from '$ui/dv/packages/my-dv-number'

// @ts-ignore
import MyDvList from '$ui/dv/packages/my-dv-list'

import MyList from '@/mixins/list'

import MyBox from '@/components/box.vue'

import Amap from '@amap/amap-vue/lib/amap'

import StadiumList from './components/stadium_list.vue'

@Component({
  components: {
    Amap,
    MyDvBox,
    MyDvBorder9,
    MyDvTitle,
    MyDvList,
    MyDvLoading,
    MyBox,
    MyDvNumber,
    VChart,
    StadiumList,
  },
  provide: {
    [THEME_KEY]: 'light',
  },
})
export default class extends Mixins(MyList) {
  mode: string = ''

  dispatchType: string = 'stadium/list'

  changeMode(mode: string) {
    this.mode = mode
  }

  option3: any = {
    title: {
      text: '销售量组成',
      textStyle: {
        color: '#e9ebee',
      },

      x: 'center',
    },
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b} : {c} ({d}%)',
    },
    legend: {
      orient: 'vertical',
      x: 'left',
      data: ['销售A', '销售B', '销售C', '销售D', '销售E'],
      textStyle: {
        color: '#e9ebee',
      },
    },

    calculable: false,
    series: [
      {
        name: '销售组成',
        type: 'pie',
        radius: '55%',
        center: ['50%', '60%'],
        data: [
          {
            value: 335,
            name: '销售A',
            itemStyle: {
              normal: {
                color: '#1afffd',
              },
            },
          },
          {
            value: 310,
            name: '销售B',
            itemStyle: {
              normal: {
                color: '#2e7cff',
              },
            },
          },
          {
            value: 234,
            name: '销售C',
            itemStyle: {
              normal: {
                color: '#ffcb89',
              },
            },
          },
          {
            value: 135,
            name: '销售D',
            itemStyle: {
              normal: {
                color: '#005ea1',
              },
            },
          },
          {
            value: 1548,
            name: '销售E',
            itemStyle: {
              normal: {
                color: '#0ad5ff',
              },
            },
          },
        ],
      },
    ],
  }

  mounted() {
    setTimeout(() => {
      this.loadAmap()
    }, 3000)
  }

  loadAmap() {
    // @ts-ignore
    const map = this.$refs.myMap.$map

    console.log(this.$refs.myMap)

    console.log(map)

    const loca = new window.Loca.Container({
      map,
    })

    loca.ambLight = {
      intensity: 0.7,
      color: '#7b7bff',
    }

    loca.dirLight = {
      intensity: 0.8,
      color: '#fff',
      target: [0, 0, 0],
      position: [0, -1, 1],
    }

    loca.pointLight = {
      color: 'rgb(240, 88, 25)',
      position: [112.028276, 31.58538, 2000000],
      intensity: 3,
      distance: 5000000,
    }

    const pl = new window.Loca.PrismLayer({
      zIndex: 10,
      opacity: 1,
      visible: false,
      hasSide: true,
    })

    const geo = new window.Loca.GeoJSONSource({
      url: 'https://a.amap.com/Loca/static/loca-v2/demos/mock_data/gdp.json',
    })
    console.log(geo)
    pl.setSource(geo)

    // top3 的城市增加文字
    const topConf: any = {
      上海市: 'https://a.amap.com/Loca/static/loca-v2/demos/images/top-one.png',
      北京市: 'https://a.amap.com/Loca/static/loca-v2/demos/images/top-two.png',
      广州市:
        'https://a.amap.com/Loca/static/loca-v2/demos/images/top-three.png',
    }
    pl.setStyle({
      unit: 'meter',
      sideNumber: 32,
      topColor: (index: number, f: any) => {
        var n = f.properties['GDP']
        return n > 7000 ? '#E97091' : '#2852F1'
      },
      sideTopColor: (index: number, f: any) => {
        var n = f.properties['GDP']
        return n > 7000 ? '#E97091' : '#2852F1'
      },
      sideBottomColor: '#002bb9',
      radius: 15000,
      height: (index: number, f: any) => {
        const props = f.properties
        const height: number = Math.max(
          100,
          Math.sqrt(props['GDP']) * 9000 - 50000
        )
        const conf = topConf[props['名称']]
        // top3 的数据，增加文字表达
        if (conf) {
          // map.add(
          //   new AMap.Marker({
          //     anchor: 'bottom-center',
          //     position: [f.coordinates[0], f.coordinates[1], height],
          //     content:
          //       '<div style="margin-bottom: 10px; float: left; font-size: 14px;height: 57px; width: 180px; color:#fff; background: no-repeat url(' +
          //       conf +
          //       '); background-size: 100%;"><p style="margin: 7px 0 0 35px; height: 20px; line-height:20px;">' +
          //       props['名称'] +
          //       '人口 ' +
          //       props['人口'] +
          //       '</p>' +
          //       '<p style="margin: 4px 0 0 35px; height: 20px; line-height:20px; color: #00a9ff; font-size: 13px;">' +
          //       props['GDP'] +
          //       ' 元' +
          //       '</p></div>',
          //   })
          // )
        }
        return height
        // return 60000 + n * 100;
      },
      // rotation: 360 * 100,
      altitude: 0,
    })

    loca.add(pl)

    // map.on('complete', () => {
    // console.log('complete')
    setTimeout(() => {
      pl.show(500)
      pl.addAnimate({
        key: 'height',
        value: [0, 1],
        duration: 500,
        easing: 'Linear',
      })
    }, 800)
    // })

    loca.animate.start()
  }
}
